































import {Vue, Component, Watch} from "vue-property-decorator";
import FindFriendType from "./FindFriend";
import HeadTop from "@/components/ShareComponent/HeadTop.vue";
import ZoomPage from "@/impView/PageSafety";
import MessageNav from "@/components/MessageAndFriend/HeadNav/index.vue";
import FindFriendListTit from "@/views/Friend/FindFriend/components/ListTit/index.vue";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import FindFriendListItem from "@/views/Friend/FindFriend/components/Item/index.vue";
import Search from "@/components/ShareComponent/Search.vue";
import { navList } from './Model';
import { getRecommendList } from './Server';
import {FindFriendStore} from "@/views/Friend/FindFriend/Store";

@Component({name: "FindFriend",components:{ HeadTop,MessageNav,FindFriendListTit,PullDownUpList,ListBottom,FindFriendListItem,Search }})
export default class FindFriend extends ZoomPage implements FindFriendType{
    PullDown = false
    UpDownBool = false
    StopUp = false
    List:any[] = []

    mounted(){
        this.loadingShow = false
        if ( this.getList && this.getList.length > 0 ) this.List = this.getList;
        else {
            this.UpDownBool = true
            this.handleUpData(true);
        }
    }

    handleGetDel(e:any){
        let { data = {},index = 0 } = e;
        this.List.splice(index,1)
    }

    handleGetSearchValue(e:any){
        this.$router.push({
            name:"searchFriend"
        })
    }

    handlePullDown(){
        let upData = FindFriendStore.getFindFriendUpData;
        upData.pageNo = 1;
        FindFriendStore.SetFindFriendUpData(upData)
        this.PullDown = true
        this.StopUp = false
        this.handleUpData(true)
    }

    handleUpDown(){
        if ( this.StopUp )return;
        let upData = FindFriendStore.getFindFriendUpData;
        upData.pageNo += 1;
        FindFriendStore.SetFindFriendUpData(upData)
        this.UpDownBool = true
        this.handleUpData()
    }

    handleUpData(bool=false){
        getRecommendList().then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < FindFriendStore.getFindFriendUpData.pageSize )this.StopUp = true;
            if ( bool )
                FindFriendStore.SetFindFriendList( res );
            else
                FindFriendStore.SetFindFriendList( FindFriendStore.getFindFriendList.concat(res) );
        })
    }

    get getCompatibility(){
        let phone = navigator.userAgent;
        let isiOS = !!phone.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        let isAndroid = phone.indexOf('Android') > -1 || phone.indexOf('Linux') > -1;
        if ( isAndroid || isiOS ){
            return true;
        }else{
            return false
        }
    }

    get getNavList(){
        if ( this.getCompatibility ){
            return navList
        }else{
            return navList.splice(1,1)
        }
    }

    get getList(){
        return FindFriendStore.getFindFriendList
    }
    @Watch("getList")
    changeList(newVal:any[]){
        this.List = newVal
    }
}
