




















import {Vue, Component, Prop, Emit} from "vue-property-decorator";
import FindFriendListItemType from "./indexType";

@Component({name: "FindFriendListItem"})
export default class FindFriendListItem extends Vue implements FindFriendListItemType{

    handleClickAdd(){
        this.$router.push({
            name:"addFriend",
            query:{
                id:this.getData && this.getData.friendId || ''
            }
        })
    }

    get getPhoto(){
        return this.getData && this.getData.headImg || this.getData.friendHeadImage || require("@/assets/icon/Basics/default.png")
    }

    @Emit("pullClickDel")
    pullClickDel(){
        return {
            data:this.getData,
            index:this.getIndex
        }
    }

    @Prop(Object)
    data!:any
    get getData(){
        return this.data
    }

    @Prop(Number)
    index!:number
    get getIndex(){
        return this.index
    }
}
