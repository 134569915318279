










import {Vue, Component} from "vue-property-decorator";
import FindFriendListTitType from "./indexType";

@Component({name: "FindFriendListTit"})
export default class FindFriendListTit extends Vue implements FindFriendListTitType{
    personIcon = require("@/views/Friend/Img/personage.png")
}
